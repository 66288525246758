<!-- src/components/SoftwareProjects.vue -->
<template>
    <div class="software-projects-page">
        <h1>Projects</h1>
        <!-- Projects Section -->
        <section id="projects">
            <p>
            Projects coming soon
            </p>
        </section>
    </div>
</template>

<script>
export default {
    name: "SoftwareProjects"
};
</script>

<style scoped>
  .software-projects-page iframe {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
  }
</style>