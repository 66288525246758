<!-- src/components/KnittingProjects.vue -->
<template>
    <div class="knitting-projects-page">
        <h1>Knitting Projects Portfolio</h1>
        <!-- Knitting Section -->
        <section id="knitting">
            <p>
            Knitting Projects coming soon
            </p>
        </section>
    </div>
</template>

<script>
export default {
    name: "KnittingProjects"
};
</script>

<style scoped>
</style>