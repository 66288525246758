<template>

  <!-- This will render the content of the route -->
  <router-view></router-view>
</template>

<script setup>
</script>

<style scoped>

body {
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  color: inherit;
}

</style>
